<template>
    <div class="comment-item">
        <!-- user -->
        <div class="comment-item-user">
            <!-- 用户名 -->
            <div class="comment-item-user-name">
                m***1（匿名）
            </div>
            <!-- 用户评论 -->
            <div class="comment-item-user-content">
                终于入手了！非常完美。一直担心的物流也没有出任何问题，这相机毒的太深了！随便一拍就很毒啊！随便一拍就很毒啊！随便一拍就很毒啊！随便一拍就很毒啊！随便一拍就很毒啊！随便一拍就很毒啊！随便一拍就很毒啊！随便一拍就很毒啊！随便一拍就很毒啊！随便一拍就很毒啊！随便一拍就很毒啊！随便一拍就很毒啊！随便一拍就很毒啊！
            </div>
        </div>
        <!-- platform -->
        <div class="comment-item-platform">
            平台回复：感谢您选择徕卡相机，M10-P相机有着M相机有史以来最安静的快门，一个看似微不足道的细节，确实良好和伟大照片之间的巨大差别。M10-P相机采用最高品质的原料与制造工艺保证了产品的耐用性，在您使用过程中有着舒适的持握感，能为您提供商家的产品和优质的服务是我们的总之。沿途的风景徕卡将与您共赏。祝您生活愉快！
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style scoped>
.comment-item{
    padding: 30px;
    border-bottom: 1px solid #ddd;
}
/* user */
.comment-item-user{
    display: flex;
}
.comment-item-user-name{
    flex-shrink: 0;
    margin-right: 100px;
}
/* platform */
.comment-item-platform{
    margin-top: 50px;
    color: var(--color);
}
</style>