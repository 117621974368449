import { render, staticRenderFns } from "./detailTabCommentItem.vue?vue&type=template&id=7d099f78&scoped=true&"
import script from "./detailTabCommentItem.vue?vue&type=script&lang=js&"
export * from "./detailTabCommentItem.vue?vue&type=script&lang=js&"
import style0 from "./detailTabCommentItem.vue?vue&type=style&index=0&id=7d099f78&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d099f78",
  null
  
)

export default component.exports